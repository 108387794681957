.form-designer-container[data-v-e74f23fc] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.form-designer-container .form-designer-left[data-v-e74f23fc] {
    width: 320px;
    height: 100%;
    padding: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    overflow-y: auto;
    background: #fff;
    border-radius: 4px;
}
.form-designer-container .form-designer-left .widget-wrapper[data-v-e74f23fc] {
      height: calc(100% - 51px);
      overflow-y: auto;
}
.form-designer-container .form-designer-left .widget-wrapper .widget-drag-wrapper[data-v-e74f23fc] {
        text-align: left;
        padding: 10px 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
}
.form-designer-container .form-designer-left .widget-wrapper .widget-drag-wrapper .widget-item[data-v-e74f23fc] {
          display: inline-block;
          width: 46%;
          -webkit-transition: -webkit-transform 0ms !important;
          transition: -webkit-transform 0ms !important;
          transition: transform 0ms !important;
          transition: transform 0ms, -webkit-transform 0ms !important;
}
.form-designer-container .form-designer-left .widget-wrapper .widget-drag-wrapper .widget-item .widget-item-body[data-v-e74f23fc] {
            padding: 8px 10px;
            font-size: var(--rootFontSize4);
            text-align: center;
            padding-top: 5px;
            cursor: move;
            border: 1px solid rgba(31, 56, 88, 0.1);
            border-radius: 0px;
}
.form-designer-container .form-designer-left .widget-wrapper .widget-drag-wrapper .widget-item .widget-item-body[data-v-e74f23fc]:hover {
              border: 1px dashed #f56c6c;
              color: #f56c6c;
}
.form-designer-container .form-designer-left .widget-wrapper .widget-drag-wrapper .widget-item[data-v-e74f23fc]:nth-child(2n) {
            margin-left: 8%;
            margin-bottom: 8%;
}
.form-designer-container .form-designer-main[data-v-e74f23fc] {
    width: calc(100% - 660px);
    height: 100%;
    overflow-y: auto;
    background: #fff;
    border-radius: 4px;
    padding: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
.form-designer-container .form-designer-main .form-drawer-container[data-v-e74f23fc] {
      width: 100%;
      height: 100%;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.form-designer-container .form-designer-main .form-drawer-container .form-tools-bar[data-v-e74f23fc] {
        position: relative;
        height: 40px;
        text-align: right;
        border-bottom: 1px solid #f1e8e8;
        background: #ffffff;
}
.form-designer-container .form-designer-main .form-drawer-container .form-tools-bar .el-button[data-v-e74f23fc] {
          font-size: var(--rootFontSize);
          vertical-align: middle;
          position: relative;
          top: -1px;
          color: #409eff;
}
.form-designer-container .form-designer-main .form-drawer-container .form-tools-bar .delete-btn[data-v-e74f23fc] {
          color: #f56c6c;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper[data-v-e74f23fc] {
        width: 100%;
        height: calc(100% - 54px);
        border-left: 1px solid #f1e8e8;
        border-right: 1px solid #f1e8e8;
        padding: 10px 12px 0px 12px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .el-form[data-v-e74f23fc] {
          height: 100%;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content[data-v-e74f23fc] {
          height: 100%;
          overflow-y: auto;
          overflow-x: hidden;
          position: relative;
          border: 1px dashed #ccc;
          padding-top: 10px;
          text-align: left;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .drawing-item[data-v-e74f23fc] {
            position: relative;
            cursor: move;
            margin-bottom: 4px;
            background: #ebeef5;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .drawing-item.unfocus-bordered:not(.design-item-actived) > div[data-v-e74f23fc]:first-child {
              border: 1px dashed #ccc;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .drawing-item .el-form-item[data-v-e74f23fc] {
              padding: 12px 10px;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .drawing-item .dynamic-table[data-v-e74f23fc] {
              padding: 12px 5px;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .drawing-item[data-v-e74f23fc]:last-of-type {
              margin-bottom: 0px;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-card[data-v-e74f23fc] {
            position: relative;
            cursor: move;
            border: 1px dashed #ccc;
            border-radius: 3px;
            padding: 5px;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-card .design-item-dragger[data-v-e74f23fc] {
              min-height: 64px;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-card .component-name[data-v-e74f23fc] {
              position: absolute;
              top: 0;
              left: 0;
              font-size: var(--rootFontSize4);
              color: #bbb;
              display: inline-block;
              padding: 0 6px;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-grid[data-v-e74f23fc] {
            position: relative;
            cursor: move;
            border: 1px dashed #ccc;
            border-radius: 3px;
            padding: 5px;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-grid .design-item-dragger[data-v-e74f23fc] {
              min-height: 64px;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-grid .design-item-grid-col[data-v-e74f23fc] {
              height: auto;
              border: 1px dashed #ccc;
              min-height: 50px;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-grid .tab-header[data-v-e74f23fc] {
              height: 40px;
              color: #0168bf;
              font-weight: 550;
              font-size: 20px;
              line-height: 36px;
              text-indent: 8px;
              border-bottom: 2px solid #E0E0E0;
              margin-bottom: 6px;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-grid .component-name[data-v-e74f23fc] {
              position: absolute;
              top: 0;
              left: 0;
              font-size: var(--rootFontSize4);
              color: #bbb;
              display: inline-block;
              padding: 0 6px;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-table[data-v-e74f23fc] {
            position: relative;
            cursor: move;
            border: 1px dashed #ccc;
            border-radius: 3px;
            overflow-x: scroll;
            padding: 5px;
            -webkit-box-sizing: border-box;
                    box-sizing: border-box;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-table .design-item-dragger[data-v-e74f23fc] {
              min-height: 80px;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .drawing-item:hover > .el-form-item[data-v-e74f23fc],
          .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-grid:hover > .el-form-item[data-v-e74f23fc],
          .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-table:hover > .el-form-item[data-v-e74f23fc],
          .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-card:hover > .el-form-item[data-v-e74f23fc] {
            background: #ddeeff;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .drawing-item > .design-tools-copy[data-v-e74f23fc],
          .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .drawing-item > .design-tools-delete[data-v-e74f23fc],
          .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-grid > .design-tools-copy[data-v-e74f23fc],
          .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-grid > .design-tools-delete[data-v-e74f23fc],
          .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-table > .design-tools-copy[data-v-e74f23fc],
          .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-table > .design-tools-delete[data-v-e74f23fc],
          .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-card > .design-tools-copy[data-v-e74f23fc],
          .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-card > .design-tools-delete[data-v-e74f23fc] {
            display: none;
            position: absolute;
            top: -10px;
            width: 22px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            border-radius: 50%;
            font-size: var(--rootFontSize4);
            border: 1px solid;
            cursor: pointer;
            z-index: 1;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .drawing-item > .design-tools-copy[data-v-e74f23fc],
          .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-grid > .design-tools-copy[data-v-e74f23fc],
          .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-table > .design-tools-copy[data-v-e74f23fc],
          .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-card > .design-tools-copy[data-v-e74f23fc] {
            right: 42px;
            top: calc(50% - 11px);
            border-color: #409eff;
            color: #409eff;
            background: #fff;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .drawing-item > .design-tools-copy[data-v-e74f23fc]:hover,
            .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-grid > .design-tools-copy[data-v-e74f23fc]:hover,
            .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-table > .design-tools-copy[data-v-e74f23fc]:hover,
            .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-card > .design-tools-copy[data-v-e74f23fc]:hover {
              background: #409eff;
              color: #fff;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .drawing-item > .design-tools-delete[data-v-e74f23fc],
          .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-grid > .design-tools-delete[data-v-e74f23fc],
          .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-table > .design-tools-delete[data-v-e74f23fc],
          .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-card > .design-tools-delete[data-v-e74f23fc] {
            right: 14px;
            top: calc(50% - 11px);
            border-color: #f56c6c;
            color: #f56c6c;
            background: #fff;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .drawing-item > .design-tools-delete[data-v-e74f23fc]:hover,
            .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-grid > .design-tools-delete[data-v-e74f23fc]:hover,
            .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-table > .design-tools-delete[data-v-e74f23fc]:hover,
            .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-card > .design-tools-delete[data-v-e74f23fc]:hover {
              background: #f56c6c;
              color: #fff;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .widget-item-body[data-v-e74f23fc] {
            padding: 0;
            margin: 0;
            font-size: 0;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .sortable-ghost[data-v-e74f23fc] {
            position: relative;
            display: block;
            overflow: hidden;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .sortable-ghost[data-v-e74f23fc]::before {
              content: " ";
              position: absolute;
              left: 0;
              right: 0;
              top: 0;
              height: 3px;
              background: #409eff;
              z-index: 2;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .widget-item.sortable-ghost[data-v-e74f23fc] {
            width: 100%;
            height: 5px;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item.sortable-ghost[data-v-e74f23fc] {
            width: 100%;
            height: 5px;
            background-color: #e6f2ff;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-actived[data-v-e74f23fc] {
            border: 2px solid #e6a23c;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-actived > .el-form-item[data-v-e74f23fc] {
              background: #e6f2ff;
              border-radius: 6px;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-actived > .dynamic-table[data-v-e74f23fc] {
              background: #e6f2ff;
              border-radius: 6px;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-actived > .design-tools-copy[data-v-e74f23fc],
            .form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-actived > .design-tools-delete[data-v-e74f23fc] {
              display: initial;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .design-item-actived > .component-name[data-v-e74f23fc] {
              color: #409eff;
}
.form-designer-container .form-designer-main .form-drawer-container .form-drawer-wrapper .design-content .el-form-item[data-v-e74f23fc] {
            margin-bottom: 0px;
}
.form-designer-container .form-designer-main .empty-info[data-v-e74f23fc] {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      font-size: var(--rootFontSize);
      color: #ccb1ea;
      letter-spacing: 4px;
}
.form-designer-container .form-designer-right[data-v-e74f23fc] {
    width: 320px;
    height: 100%;
    overflow-y: auto;
    background: #fff;
    border-radius: 4px;
}
.form-designer-container .form-designer-right .widget-config-wrapper[data-v-e74f23fc] {
      width: 100%;
      height: 100%;
      overflow-y: auto;
      border: 0px;
}
.form-designer-container .form-designer-right .widget-config-wrapper .widget-config-item[data-v-e74f23fc] {
        width: 100%;
}
.form-designer-container .form-designer-right .widget-config-wrapper .widget-config-item .el-collapse-item__header[data-v-e74f23fc] {
          background: #fff;
          font-size: var(--rootFontSize);
          padding-left: 10px;
}
.form-designer-container .form-designer-right .widget-config-wrapper .widget-config-item .el-collapse-item__header.is-active[data-v-e74f23fc] {
            border-bottom-color: #ebeef5;
            background: var(--mainThemeColor);
            color: #fff;
}
.form-designer-container .form-designer-right .widget-config-wrapper .widget-config-item .el-collapse-item__wrap[data-v-e74f23fc] {
          width: 100%;
          height: calc(100% - 49px);
          overflow-y: auto;
          padding: 10px;
          -webkit-box-sizing: border-box;
                  box-sizing: border-box;
}
.form-designer-container .form-designer-right .widget-config-wrapper .widget-config-item .el-collapse-item__wrap .el-collapse-item__content[data-v-e74f23fc] {
            padding: 0px;
}
.form-designer-container .form-designer-right .widget-config-wrapper .widget-config-item .el-collapse-item__wrap .el-collapse-item__content .unconfigable-text[data-v-e74f23fc] {
              font-size: var(--rootFontSize);
              color: #ccb1ea;
              letter-spacing: 4px;
}
.form-designer-container .form-designer-right .widget-config-wrapper .widget-config-item .el-collapse-item__wrap .el-form-item__label[data-v-e74f23fc] {
            padding: 0px;
}
.form-designer-container .form-designer-right .widget-config-wrapper .widget-config-item.is-active[data-v-e74f23fc] {
          height: calc(100% - 150px);
}
.form-designer-container .form-designer-right .widget-config-wrapper .el-scrollbar[data-v-e74f23fc] {
        height: 100%;
}
.form-designer-container .form-designer-right .widget-config-wrapper .el-form-item__content[data-v-e74f23fc] {
        text-align: left;
}
.time-range .el-date-editor[data-v-e74f23fc] {
  width: 227px;
}
.time-range[data-v-e74f23fc]  .el-icon-time {
  display: none;
}
.node-label[data-v-e74f23fc] {
  font-size: var(--rootFontSize);
}
.node-icon[data-v-e74f23fc] {
  color: #bebfc3;
}
.center-tabs .el-tabs__header[data-v-e74f23fc] {
  margin-bottom: 0 !important;
}
.center-tabs .el-tabs__item[data-v-e74f23fc] {
  width: 100%;
  text-align: center;
}
.center-tabs .el-tabs__nav[data-v-e74f23fc] {
  width: 100%;
}
.right-scrollbar .el-scrollbar__view[data-v-e74f23fc] {
  padding: 12px 18px 15px 15px;
}
.design-item-grid .el-col[data-v-e74f23fc] {
  margin-bottom: 0px;
}
.widget-item.dynamicGhost[data-v-e74f23fc] {
  width: 5px;
  height: 120px;
  background-color: #409eff;
}
.dynamic-table_item.dynamicGhost[data-v-e74f23fc] {
  width: 5px;
  height: 120px;
  background-color: #409eff;
}
.design-item-table-td .el-form-item__label[data-v-e74f23fc] {
  display: none;
}
.design-item-table-td .el-form-item__content[data-v-e74f23fc] {
  margin-left: 0 !important;
}
.wrap-container[data-v-e74f23fc] {
  width: 100%;
  height: 100%;
}
.wrap-container .flow-title[data-v-e74f23fc] {
    padding: 0px 10px;
    font-size: var(--rootFontSize3);
    font-weight: 700;
}
.wrap-container .wrap-main[data-v-e74f23fc] {
    width: 100%;
    height: calc(100% - 60px);
    padding: 0px;
}
.wrap-container .wrap-main .form-designer-container[data-v-e74f23fc] {
      width: 100%;
      height: 100%;
      padding: 10px;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
}
.change-button[data-v-e74f23fc] {
  position: fixed;
  width: 100px;
  top: 20px;
  left: 50%;
  color: white;
  font-size: 16px;
  cursor: pointer;
}
